import React, { useState } from 'react';

// --- Data --- //
import Projects from 'src/data/Projects';

// --- Components --- //
import { Section, TwoColumns } from '../Section';
import Description from '../DescriptionBlock/Description';
import { SectionHeaderBlock } from 'src/components/Section/elements';

/* --- roughNotation --- */
// import Sensor from '../Sensor';
// import RoughNotation from '../Notation';

// --- Style --- //
import sizes from 'src/style/sizes';
import { VideosBlock } from './VideosBlock';
import { SectionTitle, Paragraph } from '../Typography';
import colors from 'src/style/colors';
import { Avatar } from '../Avatars';

/**
 * @type React.FunctionComponent<{ theme: string, recruitersSection: boolean}>
 */

const ProjectsExamples = React.memo(({ theme, recruitersSection, id }) => {
  const [indexSelected, setIndex] = useState(0);

  const handleOnClick = (index) => {
    setIndex(index);
  };
  const data = Projects();

  return (
    <Section theme={theme || 'light'} id={id}>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SectionHeaderBlock>
          <SectionTitle isMobileCenter>
            Les<span css={{ color: colors.purple }}>&nbsp;réalisations&nbsp;</span>de nos élèves
          </SectionTitle>

          {recruitersSection ? (
            <Paragraph>
              Par groupe de 3 à 5 personnes, nos élèves développent un projet de site Web ou
              d'application Mobile. Pendant deux semaines, ils sont accompagnés et soutenus par les
              formateurs. Ce projet leur permet une ouverture vers le monde professionnel pour
              démontrer leurs capacités à un employeur.
            </Paragraph>
          ) : (
            // <Sensor>
            //   {({ isVisible }) => (
            <Paragraph>
              Pendant deux semaines durant le bootcamp, ils ont travaillé pour de vraies startups.{' '}
              {/* <RoughNotation
                      type="highlight"
                      multiline={true}
                      show={isVisible}
                      color="#FCC01A"
                      animate={true}
                      animationDuration={2000}
                      animationDelay={100}
                      strokeWidth={2}
                    > */}
              Ils étaient débutants
              {/* </RoughNotation> */} au départ, et ont réussi à réaliser des projets incroyables,
              à la fin de leur formation.
            </Paragraph>
            //   )}
            // </Sensor>
          )}
        </SectionHeaderBlock>
      </div>

      <TwoColumns
        reverse={true}
        sizeLeft={sizes.columnWidth.small}
        left={
          <div>
            <Description
              title={data[indexSelected].title}
              text={data[indexSelected].text}
              site={data[indexSelected].site}
              previousJobSection={false}
            />
            <ul
              css={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'flex-end',
                marginTop: 5,
              }}
            >
              {data[indexSelected].avatars.map((student) => {
                return (
                  <Avatar
                    name={student.node.image.childImageSharp.gatsbyImageData}
                    big={false}
                    key={student.node.image.childImageSharp.gatsbyImageData.images.fallback.src}
                    alt="photographie d'un ancien élève du Reacteur"
                  />
                );
              })}
            </ul>
          </div>
        }
        sizeRight={sizes.columnWidth.large}
        right={
          <VideosBlock content={data} indexSelected={indexSelected} handleOnClick={handleOnClick} />
        }
      />
    </Section>
  );
});

export default ProjectsExamples;
