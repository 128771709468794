import React from 'react';
import { FooterPreview } from './index';
import BackgroundImage from 'gatsby-background-image';
import { useHover } from 'src/utils/useHover';

/**
 * @type React.FunctionComponent<{ title?: string, subtitle?: string, carouselThumbnails?: string}>
 */

const VideoItem = ({ title, carouselThumbnails, onClick, indexSelected, index }) => {
  const [hoverRef, isHover] = useHover();
  return (
    <BackgroundImage
      onClick={onClick}
      fadeIn={false}
      fluid={carouselThumbnails}
      Tag="div"
      css={{
        height: '100%',
        width: '98%',
        objectFit: 'cover',
        backgroundSize: 'cover',
        backgroundPosition: '50%',

        overflow: 'hidden',
        cursor: 'pointer',
        marginTop: 4,
        WebkitMaskImage: '-webkit-radial-gradient(white, black)',
        borderRadius: 5,
        display: 'flex',
        alignItems: 'flex-end',
        position: 'relative',
        boxShadow: 'rgba(0, 0, 0, 0.08) 0px 4px 11px',
      }}
    >
      <div
        ref={hoverRef}
        css={{
          ':before': {
            backgroundImage: 'linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
            position: 'absolute',
            content: '""',
            display: 'block',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: index === indexSelected ? 1 : 0.8,
            transitionProperty: 'all',
            transitionDuration: '0.25s',
            transitionTimingFunction: 'cubic-bezier(0.41, 0.094, 0.54, 0.07)',
            transitionDelay: '0s',
          },
          '&:hover:before': {
            opacity: 1,
          },
        }}
      >
        <FooterPreview
          title={title}
          index={index}
          indexSelected={indexSelected}
          isHover={isHover}
        />
      </div>
    </BackgroundImage>
  );
};
export default VideoItem;
