import { graphql, useStaticQuery } from 'gatsby';
const md = require('markdown-it')();
const dedent = require('dedent-js');
var mila = require('markdown-it-link-attributes');

const Projects = () => {
  md.use(mila, {
    attrs: {
      target: '_blank',
      rel: 'noopener',
    },
  });

  const data = useStaticQuery(graphql`
    query {
      noo: file(relativePath: { eq: "projects/noo.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      guestiz: file(relativePath: { eq: "projects/guestiz.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sherpy: file(relativePath: { eq: "projects/sherpy.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 110) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hitalent: file(relativePath: { eq: "projects/hitalent.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 110) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ocamping: file(relativePath: { eq: "projects/ocamping.png" }) {
        childImageSharp {
          fluid(quality: 50, maxWidth: 110) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      poesieio: file(relativePath: { eq: "projects/poesieio.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 110) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sun: file(relativePath: { eq: "projects/sun.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 110) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      avatarsGuestiz: allGuestizAvatarsYaml {
        edges {
          node {
            image {
              childImageSharp {
                gatsbyImageData(quality: 50, width: 300, placeholder: NONE)
              }
            }
          }
        }
      }
      avatarsSun: allSunAvatarsYaml {
        edges {
          node {
            image {
              childImageSharp {
                gatsbyImageData(quality: 50, width: 300, placeholder: NONE)
              }
            }
          }
        }
      }
      avatarsNoo: allNooAvatarsYaml {
        edges {
          node {
            image {
              childImageSharp {
                gatsbyImageData(quality: 50, width: 300, placeholder: NONE)
              }
            }
          }
        }
      }
      avatarsOcamping: allOcampingAvatarsYaml {
        edges {
          node {
            image {
              childImageSharp {
                gatsbyImageData(quality: 50, width: 300, placeholder: NONE)
              }
            }
          }
        }
      }
      avatarsSherpy: allSherpyAvatarsYaml {
        edges {
          node {
            image {
              childImageSharp {
                gatsbyImageData(quality: 50, width: 300, placeholder: NONE)
              }
            }
          }
        }
      }
    }
  `);

  const dataProjects = [
    {
      index: 0,
      title: 'Noö',
      ref: 'noo',
      subtitle: 'Promo Été 2019',
      thumbnail: data.noo.childImageSharp.fluid,
      text: "Noö est une application Mobile dédiée à la monoparentalité et permettant de faciliter le quotidien des parents débordés. Que ce soit pour échanger avec d'autres parents, se faire des amis ou pourquoi pas trouver l'Amour, Noö s'assure de (bien) faire le lien.",
      site: md.render(
        dedent` Visitez le [site](https://www.noo-family.com/) de Noö pour en savoir plus.`
      ),
      link: 'https://www.youtube.com/embed/dZZuEreiaY8',
      avatars: data.avatarsNoo.edges,
    },
    {
      index: 1,
      title: 'Sherpy',
      subtitle: 'Promo Été 2019',
      thumbnail: data.sherpy.childImageSharp.fluid,
      text: "Sherpy est une application Mobile qui propose d'apprendre la pratique d'un sport de glisse en mettant pratiquants directement en lien. Il peuvent choisir de partager une piste ensemble mais aussi pourquoi pas rejoindre un cours de glisse !",
      link: 'https://www.youtube.com/embed/Jgv5xduw2_c',
      avatars: data.avatarsSherpy.edges,
      alt: 'Projets des élèves au Reacteur: Sherpy',
    },
    {
      index: 2,
      title: 'Guestiz',
      subtitle: 'Promo Automne 2018',
      thumbnail: data.guestiz.childImageSharp.fluid,
      text: 'Guestiz est une application Mobile sui permet de répondre aux besoins de visibilité des établissements, tout en facilitant la vie des influenceurs, dans leur recherche perpétuelle de contenus de qualité à partager avec leurs communautés.',
      site: md.render(
        dedent` Visitez le [site](https://www.guestiz.com/) de Guestiz pour en savoir plus.`
      ),
      link: 'https://www.youtube.com/embed/P6knz80_CD4',
      avatars: data.avatarsGuestiz.edges,
      alt: 'Projets des élèves au Reacteur: Guestiz',
    },
    {
      index: 3,
      title: "O'Camping",
      subtitle: 'Promo Été 2018',
      thumbnail: data.ocamping.childImageSharp.fluid,
      text: "O’Camping, une application Web et Mobile à destination de l'Hôtellerie de Plein Air pour agrémenter le séjour des vacanciers. C'est un véritable réseau social de mise en relation entre campeurs, équipes du camping et professionnels du tourisme.",
      link: 'https://www.youtube.com/embed/KwEhPltYWNc',
      avatars: data.avatarsOcamping.edges,
      alt: 'Projets des élèves au Reacteur: O Camping',
    },
    {
      index: 4,
      title: 'Sun',
      subtitle: 'Promo Printemps 2019',
      thumbnail: data.sun.childImageSharp.fluid,
      text: "Sun est une application Mobile permettant de faire se rencontrer les malades atteints de la même pathologie. Ce véritable réseau social permet d'aller chercher du soutien, des conseils et a pour vocation de sortir les malades de l'isolement.",
      link: 'https://www.youtube.com/embed/wpCs6BPU5sU',
      avatars: data.avatarsSun.edges,
      alt: 'Projets des élèves au Reacteur: Sun',
    },
    /*     {
      index: 5,
      title: 'Neo',
      subtitle: 'Promo Printemps 2019',
      thumbnail: data.noo.childImageSharp.fluid,
      text:
        "Neo est un site Internet qui a pour vocation d'aller à l'encontre de la sédentarité des retraités, en proposant à ces derniers de trouver des activités simples et adaptées, autour de chez eux. 👵",
      link: 'https://www.youtube.com/embed/2Q87eCvESQg',
    },
    {
      index: 6,
      title: 'Ziggy',
      subtitle: 'Promo Printemps 2019',
      thumbnail: data.noo.childImageSharp.fluid,
      text:
        "Ziggy est un site Internet qui propose d'acheter de la nourriture de qualité pour son animal de compagnie. Au delà d'une simple boutique en ligne, Ziggy fonctionne grâce à un algorithme qui va sélectionner la nourriture la plus adaptée à l'animal.",
      link: 'https://www.youtube.com/embed/nv9Ih2vPRZw',
    }, */
  ];
  return dataProjects;
};

export default Projects;
