import React, { useState } from 'react';
import BackgroundImage from 'gatsby-background-image';
import { PlayButton } from '../../components/Button';
import { VideoContainer } from '../Modal/elements';
import ModalVideo from '../Modal/ModalVideo';
import { mediaQueries } from 'src/style/breakpoints';
import { useMediaQueries } from '../../utils/MediaQueries';

/**
 * @type React.FunctionComponent<{ mainThumbnail: string}>
 */

const MainVideo = React.memo(({ className, link, mainThumbnail, noModal, loopRef }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [videoStart, setVideoStart] = useState(false);
  const mqs = useMediaQueries();

  return mqs.tabletLandscapeUp ? (
    <div
      css={{
        flex: 1,
        transitionProperty: 'all',
        transitionDuration: '0.3s',
        transitionTimingFunction: 'cubic-bezier(0.41, 0.094, 0.54, 0.07)',
        transitionDelay: '0s',
        [mediaQueries.desktopUp]: {
          maxWidth: !noModal && 623,
          maxHeight: !noModal && 448,
          boxShadow: !noModal && 'rgba(0, 0, 0, 0.07) 0px 4px 12px',
          '&:hover': { boxShadow: 'rgba(0, 0, 0, 0.1) 0px 8px 16px' },
        },
      }}
    >
      {!videoStart && (
        <BackgroundImage
          onClick={() => {
            if (noModal) {
              setVideoStart(true);
            } else {
              setModalOpen(!modalOpen);
            }
          }}
          fluid={mainThumbnail}
          fadeIn={false}
          alt="Le Reacteur est la meilleure formation de code Web et Mobile à Paris "
          Tag="div"
          className={className}
          css={{
            [mediaQueries.tabletPortraitUp]: {
              marginTop: 100,
              width: '100%',
            },
            [mediaQueries.desktopUp]: {
              marginTop: 0,
            },
            borderRadius: 5,
            WebkitMaskImage: '-webkit-radial-gradient(white, black)',
            objectFit: 'cover',
            backgroundSize: 'cover',
            backgroundPosition: '100%',
            overflow: 'hidden',
            cursor: 'pointer',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 623,
            height: 448,
            aspectRatio: 'attr(width) / attr(height)',
          }}
        >
          <div
            css={{
              '&:before': {
                backgroundImage: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))',
                position: 'absolute',
                content: '""',
                display: 'block',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                width: '100%',
                height: '100%',
                opacity: 0.8,
                transitionProperty: 'all',
                transitionDuration: '0.3s',
                transitionTimingFunction: 'cubic-bezier(0.41, 0.094, 0.54, 0.07)',
                transitionDelay: '0s',
              },
              '&:hover:before': {
                opacity: 1,
              },
            }}
          >
            <div
              css={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                opacity: 0.8,
              }}
            >
              <PlayButton
                onClick={() => {
                  if (noModal) {
                    setVideoStart(true);
                  } else {
                    setModalOpen(!modalOpen);
                  }
                }}
              />
            </div>
          </div>
        </BackgroundImage>
      )}

      {modalOpen && (
        <ModalVideo isOpen={modalOpen} link={link} onClose={() => setModalOpen(false)} />
      )}
      {videoStart && (
        <VideoContainer landing>
          <iframe
            title="youtube-video"
            style={{ height: '100%', width: '100%' }}
            src={`${link}?autoplay=1&enablejsapi=1&loop=1&playlist=${loopRef}&rel=0`}
            allow="autoplay"
            frameBorder="0"
            allowFullScreen
          />
        </VideoContainer>
      )}
    </div>
  ) : (
    <div
      css={{
        position: 'relative',
        marginTop: 42,
        boxShadow: 'rgba(0, 0, 0, 0.07) 0px 4px 12px',
      }}
    >
      {!videoStart && (
        <BackgroundImage
          onClick={() => {
            if (noModal) {
              setVideoStart(true);
            } else {
              setModalOpen(!modalOpen);
            }
          }}
          fluid={mainThumbnail}
          className={className}
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            paddingBottom: '60%',
            borderRadius: 5,
            cursor: 'pointer',
            WebkitMaskImage: '-webkit-radial-gradient(white, black)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            overflow: 'hidden',
          }}
        >
          <div
            css={{
              '&:before': {
                backgroundImage: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))',
                position: 'absolute',
                content: '""',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                opacity: 0.8,
              },
            }}
          >
            <div
              css={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                opacity: 0.8,
              }}
            >
              <PlayButton />
            </div>
          </div>
        </BackgroundImage>
      )}

      {modalOpen && (
        <ModalVideo isOpen={modalOpen} link={link} onClose={() => setModalOpen(false)} />
      )}
      {videoStart && (
        <VideoContainer landing>
          <iframe
            title="youtube-video"
            style={{ height: '100%', width: '100%' }}
            src={`${link}?autoplay=1&enablejsapi=1&loop=1&playlist=${loopRef}&rel=0&mute=1`}
            allow="autoplay"
            frameBorder="0"
            allowFullScreen
          />
        </VideoContainer>
      )}
    </div>
  );
});
export default MainVideo;
