import React from 'react';
import colors from 'src/style/colors';
import { mediaQueries } from 'src/style/breakpoints';

/**
 * @type React.FunctionComponent<{ title: string, subtitle: string}>
 */

const FooterPreview = ({ title, index, indexSelected, isHover }) => {
  return (
    <div
      css={{
        color: colors.white,
        padding: 10,
        position: 'absolute',
        left: 3,
        bottom: 3,
        width: '100%',
        maxHeight: '80%',
        // [mediaQueries.tabletPortraitUp]: {
        //   transform: isHover || index === indexSelected ? null : 'translateY(20px)',
        //   maxHeight: isHover || index === indexSelected ? '80%' : 70,
        //   transition: 'all 0.25s ease-in-out',
        // },
        [mediaQueries.phoneOnly]: {
          padding: 12,
        },
      }}
    >
      <div
        css={{
          fontWeight: 'bold',
          fontSize: 16,
          opacity: index === indexSelected ? 1 : isHover ? 1 : 0.7,
          [mediaQueries.phoneOnly]: {
            fontSize: 14,
          },
        }}
      >
        {title}
      </div>
      {/* <div
        css={{
          fontSize: 14,
          opacity: index === indexSelected ? 1 : isHover ? 1 : 0.7,
          transition: 'opacity 0.25s ease-in-out',
          [mediaQueries.phoneOnly]: {
            display: 'none',
          },
        }}
      >
        {subtitle}
      </div> */}
    </div>
  );
};

export default FooterPreview;
